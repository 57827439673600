<template>
  <div class="relative w-full h-full">
    <div
      class="absolute top-0 bottom-0 left-0 right-0 overflow-hidden overflow-y-scroll"
    >
      <slot></slot>
    </div>
  </div>
</template>
<style scoped lang="postcss">
::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
</style>
