
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import ScrollContainer from '@/components/ScrollContainerComponent.vue';
import SyllabusExplorer, {
  ISyllabusEventPayload
} from '@/components/syllabus/SyllabusExplorerComponent.vue';
import Routes from '@/router/Routes';
import { Syllabus } from '@/models';
import { useStore } from 'vuex';
import Course from '../../models/Course';
import Class from '../../models/Class';
import { UserRole } from '../../models/UserRoles';

export default defineComponent({
  components: {
    ScrollContainer,
    SyllabusExplorer
  },
  props: {
    syllabus: {
      type: Object as PropType<Syllabus>
    },
    courseList: {
      type: Array as PropType<Course[]>,
      required: true
    },
    classList: {
      type: Array as PropType<Class[]>,
      required: true
    }
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const currentUser = computed(() => store.getters['userModule/currentUser']);
    const userIsStudent = computed(
      () => currentUser.value.role === UserRole.STUDENT
    );

    const courseGroupSyllabusOptions = computed(() => {
      if (props.courseList.length === 0) {
        return [
          {
            groupLabel: 'No Courses',
            syllabusLabels: [
              {
                label: 'No Syllabus',
                downloadId: ''
              }
            ]
          }
        ];
      }
      const arrayOfGroupOptions = props.courseList.map((course) => {
        return {
          groupLabel: course.id,
          syllabusLabels: course.syllabusOptions
        };
      });
      return arrayOfGroupOptions;
    });

    const getCurrentCourse = () => {
      const currentCourseState = store.getters['courseModule/currentCourse'];
      if (!currentCourseState || !currentCourseState.syllabusOptions) {
        return courseGroupSyllabusOptions.value[0].syllabusLabels[0];
      } else {
        return currentCourseState.syllabusOptions[0];
      }
    };

    const currentCourse = ref(getCurrentCourse());
    store.dispatch('courseModule/setCurrentCourse', currentCourse.value);

    watch(currentCourse, () => {
      store.dispatch(
        'syllabusModule/fetchSyllabus',
        currentCourse.value.downloadId
      );
      store.dispatch('courseModule/setCurrentCourse', currentCourse.value);
    });

    const activeChapterName = computed(() => {
      return route.params.chapterName;
    });

    const activeTopicName = computed(() => {
      return route.params.topicName;
    });

    const activeEventName = computed(() => {
      return route.params.eventName;
    });

    function handleChapterSelected(payload: ISyllabusEventPayload) {
      router.push({
        name: Routes.SYLLABUS_CHAPTER_DETAILS,
        params: { chapterName: payload.chapterName }
      });
    }

    function handleTopicSelected(payload: ISyllabusEventPayload) {
      router.push({
        name: Routes.SYLLABUS_TOPIC_DETAILS,
        params: {
          topicName: payload.topicName || '',
          chapterName: payload.chapterName || ''
        }
      });
    }

    function handlEventNameSelected(payload: ISyllabusEventPayload) {
      router.push({
        name: Routes.SYLLABUS_TOPIC_EVENT_DETAILS,
        params: {
          eventName: payload.eventName || '',
          topicName: payload.topicName || '',
          chapterName: payload.chapterName || ''
        }
      });
    }

    return {
      userIsStudent,
      courseGroupSyllabusOptions,
      currentCourse,
      handleChapterSelected,
      handlEventNameSelected,
      handleTopicSelected,
      activeChapterName,
      activeTopicName,
      activeEventName
    };
  }
});
