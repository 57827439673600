
import Icon from '@/components/icon/Icon';
import IconComponent from '@/components/icon/IconComponent.vue';
import { Chapter, Syllabus, Topic } from '@/models';
import { computed, defineComponent, PropType, ref } from 'vue';

export interface ISyllabusEventPayload {
  chapterName: string;
  topicName?: string;
  eventName?: string;
}

export default defineComponent({
  components: {
    IconComponent
  },
  props: {
    syllabus: {
      type: Object as PropType<Syllabus>,
      required: true
    },
    activeChapterName: {
      type: String
    },
    activeTopicName: {
      type: String
    },
    activeEventName: {
      type: String
    }
  },
  emits: ['chapterSelected', 'eventNameSelected', 'topicSelected'],
  setup(props, { emit }) {
    const openTopicNames = ref([props.activeTopicName]);
    const openChapterNames = ref([props.activeChapterName]);

    // Watch for eventName changes, ensure the parent topic is open
    // Watch for topicName changes, ensure the parent category is open

    // Emit up chapter selected
    function handleChapterClick(chapter: Chapter) {
      const payload: ISyllabusEventPayload = {
        chapterName: chapter.title
      };
      emit('chapterSelected', payload);
    }

    // Expand / Collapse chapter
    function handleChapterToggle(chapter: Chapter) {
      const foundIndex = openChapterNames.value.findIndex(
        (title) => chapter.title === title
      );
      if (foundIndex > -1) {
        openChapterNames.value.splice(foundIndex, 1);
      } else {
        openChapterNames.value.push(chapter.title);
      }
    }

    // Emit up topic selected with chapter
    function handleTopicClick(chapter: Chapter, topic: Topic) {
      const payload: ISyllabusEventPayload = {
        topicName: topic.title,
        chapterName: chapter.title
      };
      emit('topicSelected', payload);
    }

    // Expand / Collapse topic
    function handleTopicToggle(topic: Topic) {
      const foundIndex = openTopicNames.value.findIndex(
        (title) => topic.title === title
      );
      if (foundIndex > -1) {
        openTopicNames.value.splice(foundIndex, 1);
      } else {
        openTopicNames.value.push(topic.title);
      }
    }

    // Emit up topic event with topic and chapter
    function handleEventClick(
      chapter: Chapter,
      topic: Topic,
      eventName: string
    ) {
      const payload: ISyllabusEventPayload = {
        topicName: topic.title,
        chapterName: chapter.title,
        eventName
      };
      emit('eventNameSelected', payload);
    }

    function isActiveChapter(chapter: Chapter) {
      return props.activeChapterName === chapter.title;
    }

    function isActiveTopic(topic: Topic) {
      return props.activeTopicName === topic.title;
    }

    function isOpenTopic(topic: Topic) {
      return openTopicNames.value.includes(topic.title);
    }

    function isOpenChapter(chapter: Chapter) {
      return openChapterNames.value.includes(chapter.title);
    }

    function isActiveEvent(eventName: string) {
      return props.activeEventName === eventName;
    }
    const chapters = computed(() => {
      return props.syllabus.chapters;
    });

    return {
      chapters,
      Icon,
      handleChapterClick,
      handleChapterToggle,
      handleEventClick,
      handleTopicClick,
      handleTopicToggle,
      isActiveChapter,
      isActiveEvent,
      isActiveTopic,
      isOpenTopic,
      isOpenChapter
    };
  }
});
